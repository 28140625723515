import clsx from 'clsx';
import Image from 'next/image';

interface AdvProps {
  title: string;
  desc: string;
  image?: string;
}

const Advs: AdvProps[] = [
  {
    title: 'TALENT BERPENGALAMAN',
    desc: 'Kamu secara tidak langsung akan merasakan mendaftar perusahaan ternama dan mendapatkan review dari talent Kerjago.',
    image: '1.svg',
  },
  {
    title: 'ULASAN MENYELURUH',
    desc: 'Kamu akan mendapatkan ulasan terkait apa yang sudah baik dan apa yang harus diperbaiki.',
    image: '2.svg',
  },
  {
    title: 'FEEDBACK KOMPREHENSIF',
    desc: 'Kamu akan mendapatkan feedback yang komprehensif mengenai cara menjawab pertanyaan interview atau membuat CV.',
    image: '3.svg',
  },
];

const Advantages = () => (
  <section className="py-12">
    <div className="flex flex-col md:flex-row w-full gap-5">
      <div className="flex flex-col w-full md:w-2/5">
        <div className="text-xl md:text-2xl lg:text-3xl font-semibold mb-8 text-left">
          <p>Mengapa Harus Pakai</p>
          <p>Kerjago</p>
        </div>
        <div className="hidden md:block">
          <Image
            alt="why"
            src="/background/why.png"
            className="object-cover object-center"
            height={500}
            width={600}
          />
        </div>
      </div>
      <div className="w-full md:w-3/5 grid grid-rows-3">
        {Advs.map((adv: AdvProps, index: number) => (
          <div
            className={clsx(
              'w-full p-5 md:p-10 flex items-center gap-5',
              index === 0 ? 'rounded-t-md bg-kerjago-primary' : '',
              index === 1 ? 'bg-[#F0F9FF]' : '',
              index === 2 ? 'rounded-b-md bg-[#F0F9FF]' : '',
            )}
            key={adv.title}
          >
            <div className="w-1/4 flex items-center justify-center">
              <div
                className={clsx(
                  'w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-full flex items-center justify-center',
                  index === 0 ? 'bg-[#F4F5F7]' : 'bg-white',
                )}
              >
                {/* <HiClipboardCheck /> */}
                <Image
                  alt={`adv_${index}`}
                  src={`/advantages/${adv.image}`}
                  height={17}
                  width={17}
                />
              </div>
            </div>
            <div className="w-3/4 flex items-start justify-center flex-col">
              <p
                className={clsx(
                  'font-bold text-sm md:text-base',
                  index === 0 ? 'text-white' : '',
                )}
              >
                {adv.title}
              </p>
              <p
                className={clsx(
                  'text-sm md:text-base',
                  index === 0 ? 'text-white' : '',
                )}
              >
                {adv.desc}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Advantages;
