import clsx from 'clsx';



import Hero from '@/components/HeroSection';
import { Link } from 'react-scroll';


const HeroCv = () => (
  <Hero
    title="Kini Hadir CV Review!"
    subtitle="Masih bingung bikin CV yang lolos screening?"
  >
    <div className="flex gap-4">
      <Link to="price" spy smooth offset={-65}>
        <button
          className={clsx(
            'btn',
            'bg-white hover:bg-kerjago-primary',
            'text-kerjago-primary hover:text-white',
            'hover:border-white border-kerjago-primary',
          )}
          type="button"
        >
          Coba Sekarang
        </button>
      </Link>
    </div>
  </Hero>
);

export default HeroCv;