import { useRouter } from 'next/router';
import React from 'react';

const CommingSoon = () => {
  const router = useRouter();
  return (
    <div className="hero min-h-screen">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">Coming Soon</h1>
          <p className="py-6 text-[18px]">
            Halo temen - temen yang sedang mencari pekerjaan dan sedang
            mempersiapkan diri untuk menggapai pekerjaan impian. Jangan lupa
            tetap cek website kami ya.
          </p>
          <button
            type="button"
            className="btn btn-outline"
            onClick={() => {
              router.push('/');
            }}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommingSoon;
