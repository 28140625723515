import clsx from 'clsx';

interface StepsProps {
  title: string;
  desc: string;
}

const Steps: StepsProps[] = [
  {
    title: 'Daftar',
    desc: 'Buat akun Kerjago dan nikmati layanan-layanan kerennya.',
  },
  {
    title: 'Pilih Layanan',
    desc: 'Pilih layanan Kerjago sesuai kebutuhanmu saat ini.',
  },
  {
    title: 'Bayar',
    desc: 'Bayar sesuai harga produk yang tertera.',
  },
  {
    title: 'Selesai',
    desc: 'Proses layanan yang telah dibeli dan dapatkan benefitnya.',
  },
];
const StepsSection = () => (
  <section className="py-12">
    <div className="flex flex-col">
      <div className="text-xl md:text-2xl lg:text-3xl font-semibold mb-10">
        <p>4 Langkah Mudah</p>
        <p>Coba Kerjago</p>
      </div>
    </div>
    <div className="">
      <ol className="items-center sm:flex gap-10">
        {Steps.map((step: StepsProps, index: number) => (
          <li className="relative mb-6 sm:mb-0" key={step.title}>
            <div className="flex items-center">
              <div className="flex z-10 justify-center items-center w-7 h-7 bg-kerjago-primary text-white font-medium rounded-full ring-0 ring-white sm:ring-8 shrink-0">
                {index + 1}
              </div>
              {Steps.length - 1 !== index ? (
                <div
                  className={clsx(
                    'hidden sm:flex w-full h-0.5 border border-kerjago-secondary/50 border-dashed',
                  )}
                />
              ) : null}
            </div>
            <div className="mt-3 sm:pr-8">
              <h3 className="text-lg font-semibold">{step.title}</h3>
              <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                {step.desc}
              </p>
            </div>
          </li>
        ))}
      </ol>
    </div>
  </section>
);

export default StepsSection;
