import Link from 'next/link';
import { HiArrowRight } from 'react-icons/hi';

import BlurImage from '@/components/blurImage';

import DataProduct from '@/data/product';

const ProductSection = () => (
  <section className="py-12" id="products">
    <div className="flex flex-col">
      <div className="text-xl md:text-2xl lg:text-3xl font-semibold mb-10 text-center">
        <p>Kami Bantu Bangun Karir Impianmu!</p>
      </div>
      <p className="text-center">
        Mempersiapkan diri dari kompetitifnya persaingan dunia rekrutmen bersama
        talent profesional Kerjago.
      </p>
    </div>
    <div className="mt-10 flex justify-center">
      <div className="max-width-component mx-auto grid grid-rows-2 grid-cols-1 md:grid-cols-2 md:grid-rows-1 justify-center gap-5 w-full">
        {DataProduct.map((data) => (
          <div
            className="card rounded-2xl card-compact bg-base-100  hover:scale-[102%] transition-all hover:shadow-xl"
            key={data.name}
          >
            <div className="relative h-60 w-full h">
              <BlurImage
                priority
                src={data.picture}
                alt={`Cover ${data.picture}`}
                className="object-cover object-top"
                layout="fill"
              />
            </div>

            <div className="card-body h-1/2">
              <h2 className="card-title mx-auto text-lg md:text-xl">
                {data.name}
              </h2>
              <p className="text-sm md:text-base text-center">
                {data.description}
              </p>
              <div className="flex items-center justify-center">
                <Link
                  href={data.url}
                  className="btn btn-link capitalize text-kerjago-primary font-bold"
                >
                  Coba Sekarang
                  <HiArrowRight className="ml-1" />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default ProductSection;
