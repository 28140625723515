import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { ProductType } from '@/types/product';

import BlurImage from '../blurImage';

const CardProduct = ({ data }: { data: ProductType }) => {
  const router = useRouter();

  const { picture, url, name, descriptionProduct } = data;

  return (
    <div className=" grid grid-cols-1 md:grid-cols-2 rounded-md">
      {data && (
        <>
          <div className="relative h-[200px] md:h-[300px] rounded-md">
            <BlurImage
              priority
              alt="product"
              src={picture}
              className="object-cover rounded-md"
            />
          </div>
          <div className="mt-4 md:px-10 md:mt-0">
            <NextLink
              href={url}
              passHref
              className="text-[18px] md:text-[40px] font-medium hover:underline">

              {name}

            </NextLink>
            <div className="text-[15px] md:text-[20px] mb-2">
              {descriptionProduct}
            </div>
            {url !== '/produk' ? (
              <button
                onClick={() => router.push(url)}
                type="button"
                className="btn btn-sm bg-[#61B0B7] hover:bg-[#4a898f] border-none"
              >
                {name}
              </button>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default CardProduct;
