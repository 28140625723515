import Head from 'next/head';
import React from 'react';

import { Daum } from '@/types/blog';
import isUrl from '@/utils/isUrl';

const BlogHeader = ({ detail }: { detail: Daum }) => {
  const {
    attributes: { title, slug, updatedAt, publishedAt, cover, seo },
  } = detail;

  let coverImageurl = cover.data.attributes.url;
  if (!isUrl(coverImageurl))
    coverImageurl =
      process.env.NEXT_PUBLIC_STRAPI_URL +
      (cover.data.attributes.formats.medium?.url ?? cover.data.attributes.url);


  return (
    <Head>
      <title>
        {title} | {process.env.NEXT_PUBLIC_WEB_NAME}
      </title>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,viewport-fit=cover"
      />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <link rel="preconnect" href="https://www.google.com " crossOrigin="" />
      <link rel="dns-prefetch" href="https://www.google.com" />
      <link
        rel="preconnect"
        href="https://www.google-analytics.com"
        crossOrigin=""
      />
      <link rel="dns-prefetch" href="https://www.google-analytics.com" />
      <meta itemProp="name" content={process.env.NEXT_PUBLIC_WEB_NAME} />
      <meta itemProp="description" content={seo.metaDescription} />
      <meta itemProp="datePublished" content={publishedAt} />
      <meta itemProp="dateModified" content={updatedAt} />
      <meta
        itemProp="image"
        content={coverImageurl}
      />
      <meta itemProp="keywords" content={seo.keywords} />
      <meta
        property="og:site_name"
        content={process.env.NEXT_PUBLIC_WEB_NAME}
      />
      <meta
        property="fb:admins"
        content={process.env.NEXT_PUBLIC_WEB_NAME?.toLocaleLowerCase()}
      />
      <meta property="og:locale" content="id_ID" />
      <meta property="og:locale:alternate" content="ms_MY" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={seo.metaDescription} />
      <meta property="og:type" content="article" />
      <meta
        property="og:url"
        content={process.env.NEXT_PUBLIC_WEB_NAME + slug}
      />
      <meta
        property="og:image"
        content={
          coverImageurl
        }
      />
      <meta
        property="og:image:secure_url"
        content={
          coverImageurl
        }
      />
      <meta
        property="og:image:alt"
        content={cover.data.attributes.alternativeText}
      />
      <meta
        property="article:author"
        content={process.env.NEXT_PUBLIC_WEB_NAME}
      />
      <meta
        property="article:publisher"
        content={process.env.NEXT_PUBLIC_WEB_NAME}
      />
      <meta property="article:published_time" content={publishedAt} />
      <meta property="article:modified_time" content={updatedAt} />
      <meta property="article:section" content="post" />
      {seo.keywords?.split(',').map((tag) => (
        <meta key={tag} property="article:tag" content={tag} />
      ))}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:site"
        content={`@${process.env.NEXT_PUBLIC_WEB_NAME?.toLocaleLowerCase()}`}
      />
      <meta
        name="twitter:creator"
        content={`@${process.env.NEXT_PUBLIC_WEB_NAME?.toLocaleLowerCase()}`}
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={seo.metaDescription} />
      <meta
        name="twitter:image"
        content={
          coverImageurl
        }
      />
      <meta
        name="twitter:image:alt"
        content={cover.data.attributes.alternativeText}
      />
      <link rel="preconnect" href={process.env.NEXT_PUBLIC_WEB_NAME} />
      <link
        rel="canonical"
        href={`${process.env.NEXT_PUBLIC_STRAPI_URL}/${slug}`}
      />
      <meta name="description" content={seo.metaDescription} />
      <meta content="index,follow" name="robots" />
      <meta name="copyright" content={process.env.NEXT_PUBLIC_WEB_NAME} />
      <meta content="Indonesian" name="language" />
      <meta content="index,follow" name="googlebot" />
      <meta content="follow, all" name="Googlebot-Image" />
      <meta content="follow, all" name="Scooter" />
      <meta content="follow, all" name="msnbot" />
      <meta content="follow, all" name="alexabot" />
      <meta content="follow, all" name="Slurp" />
      <meta content="follow, all" name="ZyBorg" />
      <meta content="follow, all" name="Scooter" />
      <meta content="true" name="MSSmartTagsPreventParsing" />
      <meta content="ALL" name="SPIDERS" />
      <meta content="ALL" name="WEBCRAWLERS" />
      <meta
        content="aeiwi, alexa, alltheWeb, altavista, aol netfind, anzwers, canada, directhit, euroseek, excite, overture, go, google, hotbot. infomak, kanoodle, lycos, mastersite, national directory, northern light, searchit, simplesearch, Websmostlinked, webtop, what-u-seek, aol, yahoo, webcrawler, infoseek, excite, magellan, looksmart, bing, cnet, googlebot"
        name="search engines"
      />
    </Head>
  );
};

export default BlogHeader;
