import Image from 'next/image';
import Link from 'next/link';

import Template from '@/layouts/template';

const Masuk = () => (
  // <Template className="mt-16">
  // </Template>
  <section className="mt-16">
    <Template>
      <div className="flex flex-col md:flex-row w-full justify-center items-center">
        <Template className="w-full md:w-1/2 px-12">
          <div>
            <p className="text-center font-semibold">Login</p>
            <p className="text-center">
              Bergabung bersama kerjago dan raih persiapkan karirmu
            </p>
            <button
              className="btn btn-ghost btn-block border-gray-100"
              type="button"
            >
              Google
            </button>
            <div className="relative flex py-5 items-center">
              <div className="flex-grow border-t border-gray-400" />
              <span className="flex-shrink mx-4 text-gray-400">
                atau login dengan Email
              </span>
              <div className="flex-grow border-t border-gray-400" />
            </div>
            <div className="form-control w-full">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="label" htmlFor="email">
                <span className="label-text font-semibold">Email</span>
              </label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="contoh@gmail.com"
                className="input input-bordered w-full"
              />
            </div>
            <div className="form-control w-full">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="label" htmlFor="password">
                <span className="label-text font-semibold">Password</span>
              </label>
              <input
                type="text"
                id="password"
                name="password"
                placeholder="min. 8 character"
                className="input input-bordered w-full"
              />
            </div>
            <div className="flex justify-between items-center">
              <div className="form-control">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="label cursor-pointer">
                  <input type="checkbox" checked className="checkbox" />
                  <span className="label-text ml-2">Ingat saya</span>
                  <Link href="/login" className="link">
                    Lupa sandi?
                  </Link>
                </label>
              </div>
            </div>
          </div>
        </Template>
        <div className="hidden md:block md:w-1/2">
          <div className="w-full">
            <Image alt={"login logo"} src="/background/login.png" height={1000} width={1000} />
          </div>
        </div>
      </div>
    </Template>
  </section>
);

export default Masuk;
