import Image from 'next/image';

const ContentProduct = () => (
  <section className="py-12">
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row text-base md:text-lg lg:text-xl">
        <div className="flex flex-col w-full md:w-1/2 gap-10">
          <p className="text-xl md:text-2xl lg:text-3xl font-semibold mx-auto">
            Apa itu CV Review?
          </p>
          <p>
            Curriculum vitae kamu langsung diulas dan diberi masukan oleh orang
            yang berpengalaman di dunia HR.
          </p>
          <p>
          Rekomendasi dari Talent Kerjago dapat kamu gunakan untuk memperbaiki CV sebelum melamar kerja di posisi impian!
          </p>
        </div>
        <div className="md:w-1/2 w-full flex items-center justify-center mt-12 md:mt-0">
          <Image
          alt="mockup interview img"
            src="/background/mockup-interview.svg"
            className="rounded-lg"
            width={500}
            height={375}
          />
        </div>
      </div>
    </div>
  </section>
);

export default ContentProduct;
