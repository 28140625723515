import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { changeNavbarUrl } from '@/redux/navbarUrlSlice';

import CardProduct from '@/components/productCard';

import DataProduct from '@/data/product';

import PageContainer from '@/layouts/container';

const Product = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavbarUrl('product'));
  }, [dispatch]);

  return (
    <PageContainer>
      <div className="flex flex-col justify-center  items-center ">
        <div className="max-width-component text-left text-[60px] mt-[80px] font-semibold px-10 w-full  ">
          Produk
        </div>
        <div className="max-width-component  w-full px-10 grid grid-cols-1 gap-6 mt-10">
          {DataProduct.map((data) => (
            <CardProduct key={data.name} data={data} />
          ))}
        </div>
      </div>
    </PageContainer>
  );
};

export default Product;
