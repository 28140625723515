import clsx from 'clsx';
import { Link } from 'react-scroll';

import BlurImage from '@/components/blurImage';

import Template from '@/layouts/template';

const BackgroundColor = () => (
  <>
    <div
      className={clsx(
        'absolute w-full h-full',
        'bg-gradient-to-tl',
        'from-kerjago-light to-kerjago-primary',
      )}
    />
    <div
      className={clsx(
        'absolute w-[400px] h-[400px] md:w-[500px] md:h-[500px]',
        '-left-1/2 md:-left-36 -bottom-52 rotate-[20deg]',
        'rounded-[100px] md:rounded-[150px] border-2',
        'border-kerjago-primary',
      )}
    />
    <div
      className={clsx(
        'absolute w-[500px] h-[1000px] md:w-[1500px] md:h-[1500px]',
        'rotate-[20deg] origin-bottom-left -right-3/4 md:-right-1/3 bottom-0',
        'rounded-[100px] md:rounded-[150px]',
        'bg-kerjago-primary',
      )}
    />
    <div
      className={clsx(
        'absolute w-[700px] h-[700px] md:w-[1100px] md:h-[1100px]',
        'border-2 rounded-full left-1/2 md:-right-40 md:top-0',
        'border-kerjago-primary',
      )}
    />
  </>
);

const NewHero = () => (
  <div className="flex min-h-screen text-left relative justify-center items-center overflow-hidden text-white">
    <BackgroundColor />
    <Template>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
        <div className={clsx('relative w-full my-auto')}>
          <div className="font-semibold text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl">
            <p>Kembangkan</p>
            <p>Dirimu</p>
          </div>
          <p className="my-5 md:my-10 text-lg md:text-xl">
            Persiapkan dirimu untuk memasuki dunia kerja
          </p>

          <Link to="price" spy smooth offset={-65}>
            <button
              type="button"
              className="btn btn-md md:btn-lg btn-wide bg-kerjago-yellow hover:bg-kerjago-yellow border-none scroll-smooth"
            >
              <span className="text-kerjago-primary capitalize">
                Coba Sekarang
              </span>
            </button>
          </Link>
        </div>
        <div className="relative mx-auto hidden md:block">
          <div className="w-[300px] h-[350px] lg:w-[400px] lg:h-[450px] absolute bg-kerjago-yellow -right-4 -bottom-3" />
          <BlurImage
            src="/background/landing-page.png"
            className="rounded-tr-[100px] rounded-bl-[100px] object-cover object-top"
            alt="hero-image"
            width={450}
            height={500}
          />
        </div>
      </div>
    </Template>
  </div>
);

export default NewHero;
