import { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';

import { changeNavbarUrl } from '@/redux/navbarUrlSlice';

import formatDate from '@/utils/formatDate';
import isUrl from '@/utils/isUrl';

import { Daum } from '@/types/blog';

import BlogCard from '@/components/blogCard';
import BlurImage from '@/components/blurImage';

import BlogHeader from './meta';

export type BlogDetailProps = {
  detail: Daum;
  blogList: Daum[];
};

const BlogDetail = ({ detail, blogList }: BlogDetailProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavbarUrl('blog'));
  }, [dispatch]);

  const {
    attributes: { title, content, publishedAt, cover },
  } = detail;

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef.current || !window) return;

    var headerOffset = 300;
    var elementPosition = contentRef.current.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }, [contentRef]);

  let coverImageurl = cover.data.attributes.url;
  if (!isUrl(coverImageurl))
    coverImageurl =
      process.env.NEXT_PUBLIC_STRAPI_URL +
      (cover.data.attributes.formats.medium?.url ?? cover.data.attributes.url);

  return (
    <>
      {detail && <BlogHeader detail={detail} />}
      {detail && (
        <>
          <div className="flex flex-col justify-center mt-16 items-center  md:px-0">
            <div className="w-full mb-5 md:mb-10 px-0">
              <img
                width="100%"
                src={coverImageurl}
                className="object-cover max-h-[197px] md:max-h-[660px]"
                alt={cover.data.attributes.alternativeText}
              />
            </div>
            <div
              ref={contentRef}
              className="flex flex-col justify-center max-w-5xl"
            >
              <div className="text-center text-xl md:text-3xl xl:text-5xl font-bold mb-5 md:mb-10 sm:mx-2 md:mx-4 ">
                {title}
              </div>
              <div className="text-center text-gray-600 text-md md:text-lg lg:text-xl mb-5 md:mb-10">
                {formatDate(publishedAt)}
              </div>
            </div>
            <div
              className="prose lg:prose-xl mx-5"
              style={{ wordBreak: 'break-word' }}
            >
              <ReactMarkdown>{content}</ReactMarkdown>
            </div>
          </div>
          {blogList && (
            <div className="flex flex-col justify-center  items-center ">
              <div className="max-width-component text-left text-4xl mt-20 mb-4  font-semibold px-10 w-full leading-tight">
                Artikel Lainnya
              </div>
              <div className="max-width-component h-[10px] w-full px-10" />
              <div className="max-width-component w-full grid grid-cols-1 md:grid-cols-3 gap-6 px-10">
                {blogList.map((blog) => (
                  <BlogCard key={blog.id} blog={blog} />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BlogDetail;
