import { BenefitProps } from '@/types/product';

import BenefitCard from '@/components/benefitCard';

interface BenefitProductProps {
  benefits: BenefitProps[];
}

const BenefitProduct = ({ benefits }: BenefitProductProps) => (
  <section className="py-12">
    <div className="flex flex-col">
      <p className="text-xl md:text-2xl lg:text-3xl font-semibold mx-auto mb-10">
        Benefit Yang Kamu Dapatkan
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {benefits.map((benefit) => (
          <BenefitCard data={benefit} key={benefit.title} />
        ))}
      </div>
    </div>
  </section>
);

export default BenefitProduct;
