import PartnerProduct from '@/components/PartnerSection';
import TestimoniProduct from '@/components/TestimoniSection';


import Template from '@/layouts/template';

import BenefitProduct from './benefit';
import ContentProduct from './content';
import HeroCv from './hero';
import PriceProduct from '../../components/productPrice';
import shuffleArray from '@/utils/shuffleArray';
import { CvReviewBenefits } from '@/data/product/cv-review';
import { testimonial } from '@/data/testimony';
import { CvReviewPrice } from '@/data/product';

const CVReviewModule = () => (
  <>
    <div className="bg-kerjago-primary">
      <Template className="mt-16">
        <HeroCv />
      </Template>
    </div>
    <Template>
      <ContentProduct />
      <BenefitProduct benefits={CvReviewBenefits} />
      <PartnerProduct />
    </Template>
    <div className="bg-[#F4F5F7]">
      <Template>
        <TestimoniProduct dataTestimoni={testimonial} />
      </Template>
    </div>
    <Template>
      <PriceProduct cvreview={CvReviewPrice} />
    </Template>
  </>
);

export default CVReviewModule;
