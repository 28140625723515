import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { changeNavbarUrl } from '@/redux/navbarUrlSlice';

import CommingSoon from '@/components/comingSoon';

const Daftar = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavbarUrl('register'));
  }, [dispatch]);
  return <CommingSoon />;
};

export default Daftar;
