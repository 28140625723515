import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { changeNavbarUrl } from '@/redux/navbarUrlSlice';

import { Daum } from '@/types/blog';

import PartnerProduct from '@/components/PartnerSection';
import TestimoniProduct from '@/components/TestimoniSection';

import { MainPrice } from '@/data/product';

import Template from '@/layouts/template';

import PriceProduct from '../../components/productPrice';
import Advantages from './advantages';
import NewHero from './hero/hero';
import ProductSection from './products';
import StepsSection from './steps';
import { NextSeo } from 'next-seo';
import { MetaHome } from '@/data/meta';
import shuffleArray from '@/utils/shuffleArray';
import { testimonial } from '@/data/testimony';

export type HomeProps = {
  blogList: Daum[];
};

// const Home = ({ blogList }: HomeProps) => {
const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeNavbarUrl(''));
  }, [dispatch]);

  return (
    <>
      <NextSeo
        title={MetaHome.title}
        description={MetaHome.description}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: MetaHome.keywords.join(','),
          },
        ]}
        openGraph={{
          type: 'website',
          url: MetaHome.url,
          description: MetaHome.description,
          images: [
            {
              url: '/thumb_hd.png',
              width: 1280,
              height: 720,
              alt: 'Kerjago Cover HD',
              type: 'image/png',
            },
            {
              url: '/thumb_square.png',
              width: 500,
              height: 500,
              alt: 'Kerjago Cover Square',
              type: 'image/png',
            },
          ],
        }}
      />
      <div>
        <NewHero />
      </div>
      <div className="bg-[#F5F5F5]">
        <Template>
          <PartnerProduct />
        </Template>
      </div>
      <Template>
        <StepsSection />
      </Template>
      <div className="bg-[#F5F5F5]">
        <Template>
          <ProductSection />
        </Template>
      </div>
      <Template>
        <Advantages />
      </Template>
      <div className="bg-[#F5F5F5]">
        <Template>
          <TestimoniProduct dataTestimoni={testimonial} />
        </Template>
      </div>
      <Template>
        <PriceProduct cvreview={MainPrice} />
      </Template>
      {/* <BlogSection blogList={blogList} /> */}
    </>
  );
};

export default Home;
