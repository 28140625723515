import NextLink from 'next/link';

import formatDate from '@/utils/formatDate';
import isUrl from '@/utils/isUrl';

import BlurImage from '../blurImage';
import { BlogCardProps } from './types';

const BlogCard = ({ blog }: BlogCardProps) => {
  const {
    attributes: { title, slug, publishedAt, cover, seo },
  } = blog;

  let coverImageurl = cover.data.attributes.url;
  if (!isUrl(coverImageurl))
    coverImageurl =
      process.env.NEXT_PUBLIC_STRAPI_URL +
      (cover.data.attributes.formats.medium?.url ?? cover.data.attributes.url);

  return (
    <NextLink href={`/blog/${slug}`} passHref className=" rounded-md  ">
      <div className="relative shadow-lg rounded-md ">
        <img
          src={coverImageurl}
          alt=""
          className="object-cover rounded-md h-[250px] w-full"
        />
      </div>
      <div className="mt-4 text-[15px]">{formatDate(publishedAt)}</div>
      <div className="mt-2 text-[18px] font-medium">{title}</div>
      <div className="hidden md:block text-[15px] md:mt-2 md:overflow-hidden md:line-clamp-2">
        {seo.metaDescription}
      </div>
    </NextLink>
  );
};

export default BlogCard;
