import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { changeNavbarUrl } from '@/redux/navbarUrlSlice';

import { Daum } from '@/types/blog';

import BlogCard from '@/components/blogCard';

export type BlogListProps = { blogList: Daum[] };

const BlogList = ({ blogList }: BlogListProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavbarUrl('blog'));
  }, [dispatch]);

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="max-width-component text-left text-[60px] mt-16  font-semibold px-10 w-full  ">
        Blog
      </div>
      <div className="max-width-component h-[10px] w-full px-10" />
      <div className="max-width-component w-full grid grid-cols-1 md:grid-cols-3 gap-6 px-10">
        {blogList &&
          blogList.map((blog) => <BlogCard key={blog.id} blog={blog} />)}
      </div>
    </div>
  );
};

export default BlogList;
